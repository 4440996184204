import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const act = localStorage.getItem("cUserDivehood")
  ? JSON.parse(localStorage.getItem("cUserDivehood"))["data"].accessToken
  : "no";

@Injectable({
  providedIn: 'root'
})


export class NormalizationService {

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${act}`,
    }),
  };

  getNormalizations(isLiveaboard: any = false) {
    return this.http
      .get(`${environment.apiUrl}/ProductTemplates/GetProductTemplates?isLiveaboard=${isLiveaboard}`);
  }

  saveNormalizations(data) {
    return this.http
      .post(`${environment.apiUrl}/ProductTemplates/AddProductTemplate`, data);
  }

  getTerms() {
    return this.http
      .get(`${environment.apiUrl}/TermsTemplate/GetTermTemplates`);
  }
  saveTerms(data) {
    return this.http
      .post(`${environment.apiUrl}/TermsTemplate/AddtermsTemplate`, data);
  }

  getServices() {
    return this.http
      .get(`${environment.apiUrl}/ExtraServicesTemplate/GetServicesTemplates`);
  }
  saveServices(data) {
    return this.http
      .post(`${environment.apiUrl}/ExtraServicesTemplate/AddServiceTemplate`, data);
  }
}


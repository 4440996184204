import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
const act = localStorage.getItem("cUserDivehood")
  ? JSON.parse(localStorage.getItem("cUserDivehood"))["data"].accessToken
  : "no";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  invokeEvent: Subject<any> = new Subject();

  BaseURL = `${environment.apiUrl}/`;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${act}`,
    }),
  };

  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      enctype: "multipart/form-data",
      Authorization: `Bearer ${act}`,
    }),
  };
  constructor(private http: HttpClient) {}

  /* ********************
   *** Genral finacial API's
   ***********************/

  //  BackEndFinance/GetLedgerSummaryReport
  getReservationsList(
    pageNo,
    limit,
    status,
    reservationId,
    itemName,
    itemStartDate,
    customerName,
    customerMobile,
    customerEmail,
    reservationDateFrom,
    reservationDateTo,
    paymentType
  ) {
    let url = `${this.BaseURL}BackEndFinance/GetReservationsList?pageNo=${pageNo}&limit=${limit}`;
    if (status) url += `&status=${status}`;
    if (reservationId) url += `&reservationId=${reservationId}`;
    if (itemName) url += `&itemName=${itemName}`;
    if (itemStartDate) url += `&itemStartDate=${itemStartDate}`;
    if (customerName) url += `&customerName=${customerName}`;
    if (customerMobile) url += `&customerMobile=${customerMobile}`;
    if (customerEmail) url += `&customerEmail=${customerEmail}`;
    if (reservationDateFrom)
      url += `&reservationDateFrom=${reservationDateFrom}`;
    if (reservationDateTo) url += `&reservationDateTo=${reservationDateTo}`;
    if (paymentType) url += `&paymentType=${paymentType}`;

    return this.http.get(url);
  }

  //  BackEndFinance/GetLedgerSummaryReport
  getLedgerSummaryReport(startDate, endDate) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetLedgerSummaryReport?startDate=${startDate}&endDate=${endDate}`
    );
  }

  //  BackEndFinance/GetLedgerSummaryReport?SPId=31
  getLedgerSummaryById(SPId, startDate, endDate) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetLedgerSummaryReport?SPId=${SPId}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  // BackEndFinance/GetLedgerDetailedReport?BranchId=64
  getLedgerDetailedReport(BranchId) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetLedgerDetailedReport?BranchId=${BranchId}`
    );
  }

  // BackEndFinance/GetSettlementsList?pageNo=0&limit=100&BranchId=64
  getTransfers(pageNo, limit, BranchId) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetSettlementsList?pageNo=${pageNo}&limit=${limit}&BranchId=${BranchId}`
    );
  }

  /* ********************
   *** Busseniss Owner API's
   ***********************/

  // BackEndFinance/GetReservationsList (invoices)
  getList(pageNo, limit, tableStatus, key) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetReservationsList?pageNo=${pageNo}&limit=${limit}&Status=${tableStatus}&SearchQuery=${key}`
    );
  }

  // BackEndFinance/GetReservationById (invoice details)
  getItem(id) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetReservationById?InvoiceId=${id}`
    );
  }

  // CreateCancelReservationRequest
  requsetCancellation(obj: any) {
    return this.http.post(
      `${this.BaseURL}SPFinance/CreateCancelReservationRequest`,
      obj
    );
  }

  // BackEndFinance/GetCancelReservationRequests
  getCnlRequests(pageNo, limit, isNew, key) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetCancelReservationRequests?pageNo=${pageNo}&limit=${limit}&GetNewOnly=${isNew}&SearchQuery=${key}`
    );
  }

  /* ********************
   *** Super admin API's
   ***********************/
  //  BackEndFinance/GetReservationsList?pageNo=0&limit=20
  getAdminList(pageNo, limit, tableStatus, key) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetReservationsList?pageNo=${pageNo}&limit=${limit}&Status=${tableStatus}&SearchQuery=${key}`
    );
  }

  // BackEndFinance/GetReservationById?InvoiceId=5
  getAdminInvoice(id) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetReservationById?InvoiceId=${id}`
    );
  }

  // BackEndFinance/CaptureFreeCancelation?InvoiceId=5
  captureFreeCancelation(id) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/CaptureFreeCancelation?InvoiceId=${id}`
    );
  }

  // BackEndFinance/GetCancelReservationRequests
  // getCancelRequests(limit, isNew) {
  //   return this.http
  //     .get(`${this.BaseURL}BackEndFinance/GetCancelReservationRequests?pageNo=0&limit=${limit}&GetNewOnly=${isNew}`);
  // }

  // CreateCancelReservationRequest
  confiremCancellation(obj: any) {
    return this.http.post(
      `${this.BaseURL}SAFinance/RespondToCancelReservationRequest`,
      obj
    );
  }

  // SAUsers/GetSPsList?pageNo=0&limit=20
  getSpList(pageNo, limit, userState, isExport, name, email, mobile) {
    let url = `${this.BaseURL}SAUsers/GetSPsList?pageNo=${pageNo}&limit=${limit}`;
    if (userState) url += `&userState=${userState}`;
    if (isExport) url += `&isExport=${isExport}`;
    if (name) url += `&name=${name}`;
    if (email) url += `&email=${email}`;
    if (mobile) url += `&mobile=${mobile}`;
    return this.http.get(url);
  }

  // SuperAdminFinance/AddSettlement
  AddSettlement(obj: any) {
    return this.http.post<any>(
      `${this.BaseURL}SAFinance/AddSettlement`,
      obj,
      this.httpOptionsUploadFiles
    );
  }

  // POST /api/back/v1/SAFinance/UpdateSettlement
  updateSettlement(obj: any) {
    return this.http.post<any>(
      `${this.BaseURL}SAFinance/UpdateSettlement`,
      obj,
      this.httpOptionsUploadFiles
    );
  }

  // POST /api/back/v1/SAFinance/DeleteSettlement
  delete(id: any) {
    return this.http.post(
      `${this.BaseURL}SAFinance/DeleteSettlement?voucherId=${id}`,
      this.httpOptions
    );
  }

  // BackEndFinance/GetAddons?InvoiceId=104
  getAddons(id) {
    return this.http.get(
      `${this.BaseURL}BackEndFinance/GetAddons?InvoiceId=${id}`
    );
  }

  // Update the list after adding new item or updating item
  updateTheListAfterSumbitTheModel() {
    this.invokeEvent.next("updateTheList");
  }
}

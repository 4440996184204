import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
const act = localStorage.getItem('cUserDivehood') ? JSON.parse(localStorage.getItem('cUserDivehood'))['data'].accessToken : 'no';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${act}`
    })
  };

  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': `Bearer ${act}`
    })
  };
  errorData: { errorTitle: string; errorDesc: string; };
  constructor(private http: HttpClient) { }


  // Get Organization List for Busseniss Owner
  getOrganization(branchId, isNormalization = false, packageId = 0) {
    if (isNormalization == true) {
      return this.http.get(`${environment.apiUrl}/BackEndOrganizations/GetOnlyBranchOrgsNormalazation?branchId=${branchId}`, this.httpOptions);
    }
    else {
      return this.http.get(`${environment.apiUrl}/BackEndOrganizations/GetOnlyBranchOrgs?branchId=${branchId}`, this.httpOptions);
    }
  }

  // Get Organization Courses List for Busseniss Owner
  getOrganizationCourses(organizationId) {
    return this.http
      .get(`${environment.apiUrl}/BackEndOrgCourses/GetByOrganizationId?organizationId=${organizationId}`, this.httpOptions);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LiveaboardService {
  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      enctype: "multipart/form-data",
    }),
  };
  constructor(private http: HttpClient) {}

  SyncTrips() {
    return this.http.get(`${environment.apiUrl}/SALiveaboards/SyncLiveaboard`);
  }

  // SuperAdminLiveaboards/Get?PageNo=0&limit=10
  getList(pageNo, limit, key) {
    return this.http.get(
      `${environment.apiUrl}/SALiveaboards/Get?pageNo=${pageNo}&limit=${limit}&SearchQuery=${key}`
    );
  }

  syncInseanqTrips() {
    return this.http.get(
      `${environment.apiUrl}/BackEndLiveaboards/SyncTripsWithInseanq`
    );
  }

  syncInseanqLiveaboards() {
    return this.http.get(
      `${environment.apiUrl}/BackEndLiveaboards/SyncLiveaboardsWithInseanq`
    );
  }

  // SuperAdminLiveaboards/Delete?LiveaboardId=3
  delete(id: any) {
    return this.http.post(
      `${environment.apiUrl}/SALiveaboards/Delete?LiveaboardId=${id}`,
      id
    );
  }

  // Add main Data
  addMainData(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/AddMainData`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  translateData(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/TranslateData`,
      requestData
    );
  }

  getMainData(id) {
    return this.http.get(
      `${environment.apiUrl}/SALiveaboards/GetMainData?LiveaboardId=${id}`,
      this.httpOptionsUploadFiles
    );
  }

  // POST /api/ios/v1/SALiveaboards/UpdateMainData
  updateMainData(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/UpdateMainData`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // /api/ios/v1/SALiveaboards/UpdateFeatures
  updateFeatures(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/UpdateFeatures`,
      requestData
    );
  }

  // /api/ios/v1/SALiveaboards/GetCabins?LiveaboardId=1
  getCabins(LiveaboardId) {
    return this.http.get(
      `${environment.apiUrl}/SALiveaboards/GetCabins?LiveaboardId=${LiveaboardId}`
    );
  }

  // SuperAdminLiveaboards/DeleteCabin?CabinId=0
  deleteCabin(cabinId) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/DeleteCabin?CabinId=${cabinId}`,
      cabinId
    );
  }

  // api/ios/v1/SALiveaboards/AddCabin
  addCabin(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/AddCabin`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // api/ios/v1/SALiveaboards/UpdateCabin
  updateCabin(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/UpdateCabin`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // /api/ios/v1/SAAmenities/Get
  getAmenities() {
    return this.http.get(`${environment.apiUrl}/SAAmenities/Get`);
  }

  // SuperAdminAmenities/Add
  addAmenity(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAAmenities/Add`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // SuperAdminAmenities/Update
  updateAmenity(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAAmenities/Update`,
      requestData
    );
  }

  // SuperAdminAmenities/GetById?AmenityId=2
  getAmenity(amenityId) {
    return this.http.get(
      `${environment.apiUrl}/SAAmenities/GetById?AmenityId=${amenityId}`
    );
  }

  // SuperAdminLiveaboards/DeleteCabin?CabinId=0
  deleteAmenity(amenityId) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAAmenities/Delete?AmenityId=${amenityId}`,
      amenityId
    );
  }

  // /api/ios/v1/SALiveaboards/UpdateIsActive
  updateIsActive(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/UpdateIsActive`,
      requestData
    );
  }

  // GET /api/ios/v1/SALiveaboards/GetFeatures
  getFeatures(LiveaboardId) {
    return this.http.get(
      `${environment.apiUrl}/SALiveaboards/GetFeatures?LiveaboardId=${LiveaboardId}`
    );
  }

  // SuperAdminLiveaboards/UpdateImageDisplayOrder?Type=Liveaboard
  updateImageDisplayOrder(type, requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/SALiveaboards/UpdateImageDisplayOrder?Type=${type}`,
      requestData
    );
  }

  // SuperAdminLiveaboards/GetIsActive?LiveaboardId=15
  getIsActive(LiveaboardId) {
    return this.http.get(
      `${environment.apiUrl}/SALiveaboards/GetIsActive?LiveaboardId=${LiveaboardId}`
    );
  }

  // For SP Liveaboard trips

  // BackEndLiveaboards/GetCabins?LiveaboardId=15&TripId=f429465e-ad6b-4553-8a20-1d6cfb6f9285
  getLiveaboardCabins(LiveaboardId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndLiveaboards/GetCabins?LiveaboardId=${LiveaboardId}`
    );
  }

  liveaboardCabinsForRealTrip(startDate) {
    return this.http.get(
      `${environment.apiUrl}/BackEndLiveaboards/GetCabinsForTrip?startDate=${startDate}`
    );
  }

  GetCabinsForLiveaboardFromExtrenalCabinInfo(liveaboardId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndLiveaboards/GetCabinsForLiveaboardFromExtrenalCabinInfo?liveaboardId=${liveaboardId}`
    );
  }

  // BackEndTrips/GetCabins?TripId=f429465e-ad6b-4553-8a20-1d6cfb6f9285
  getTripCabins(TripId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndTrips/GetCabins?TripId=${TripId}`
    );
  }

  // BackEndTrips/GetCabinById?TripId=f429465e-ad6b-4553-8a20-1d6cfb6f9285&CabinId=1
  getSelectedCabinById(TripId, cabinId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndTrips/GetCabinById?TripId=${TripId}&CabinId=${cabinId}`
    );
  }

  // BackEndTrips/AddCabin
  addTripCabin(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndTrips/AddCabin`,
      requestData
    );
  }

  // api/ios/v1/SALiveaboards/UpdateCabin
  updateTripCabin(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndTrips/UpdateCabin`,
      requestData
    );
  }

  // BackEndTrips/DeleteCabin?TripId=f429465e-ad6b-4553-8a20-1d6cfb6f9285&CabinId=3
  deleteTripCabin(TripId, cabinId) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndTrips/DeleteCabin?TripId=${TripId}&CabinId=${cabinId}`,
      cabinId
    );
  }
}
